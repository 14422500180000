/**
 * List Schema of Tokens Price
 * @author Lopes
 */

import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import TokenCellRender from '@/components/render/TokenCellRender.vue'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import TruncateCellRender from '@/components/render/TruncateCellRender.vue'
import TokensPriceChart from '@/components/tokensprice/TokensPriceChart.vue'
import TokensSwapButton from '@/components/tokensprice/TokensSwapButton.vue'
import {TokensTableItem} from '@/model/resource/TokensTableItem'
import {FlamingoSwapHelper, BSNeo3Constants} from '@cityofzion/bs-neo3'

export class SimpleListTokensTableItemSchema extends DefaultSchema {
  readonly name = 'ListTokensTableItem'

  isMobile = false

  orderByList = [
    'tokens',
    'price',
    'priceVariationLastDay',
    'priceVariationLastWeek',
    'marketCap',
    'totalSupply',
  ]

  swappableTokensSymbol = FlamingoSwapHelper.listSwappableTokensSymbol(
    BSNeo3Constants.DEFAULT_NETWORK
  )

  readonly fieldSet: FieldSet<TokensTableItem> = {
    tokens: (schema): FieldComponent => ({
      is: TokenCellRender,
      bind: {
        imageUrl: schema.model.imageUrl,
        symbol: schema.model.symbol,
        slug: schema.model.marketInformation?.slugDapp,
      },
    }),
    price: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.marketInformation
          ? schema.model.priceFormatted
          : '',
      },
    }),
    priceVariationLastDay: (schema): FieldComponent => ({
      is: PercentageProgressRender,
      bind: {
        percentageValue: schema.model.marketInformation
          ? schema.model.priceVariationLastDayFormatted
          : undefined,
        showArrows: false,
        inputClass: 'text-lg md:text-lg',
        rounded: false,
      },
    }),
    priceVariationLastWeek: (schema): FieldComponent => ({
      is: PercentageProgressRender,
      bind: {
        percentageValue: schema.model.marketInformation
          ? schema.model.priceVariationLastWeekFormatted
          : undefined,
        showArrows: false,
        inputClass: 'text-lg md:text-lg',
        rounded: false,
      },
    }),
    marketCap: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.marketInformation
          ? schema.model.marketCapFormatted
          : '',
      },
    }),
    totalSupply: (schema): FieldComponent => ({
      is: TruncateCellRender,
      bind: {
        fullValue: schema.model.marketInformation
          ? schema.model.totalSupplyFormatted
          : '',
        symbol: schema.model.marketInformation ? schema.model.symbol : '',
      },
    }),
    priceVariationLastWeekGraphic: (schema): FieldComponent => ({
      is: TokensPriceChart,
      bind: {
        prices: schema.model.marketInformation
          ? schema.model.marketInformation?.variationGraphValues
          : [],
        priceVariationLastWeek: schema.model.marketInformation
          ? schema.model.priceVariationLastWeekFormatted
          : 0,
        class: 'py-2 text-base xl:text-lg',
      },
    }),
    buttonSwap: (schema): FieldComponent => ({
      is: TokensSwapButton,
      bind: {
        isSwappable: this.swappableTokensSymbol.includes(schema.model.symbol!),
        token: schema.model,
        class: schema.model.marketInformation
          ? 'py-2 text-base xl:text-lg'
          : 'hidden',
      },
    }),
  }
}
