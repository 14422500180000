var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tokens-swap-button"},[_c('v-popover',{attrs:{"popover-inner-class":{
      hidden: _vm.isSwappable,
      'bg-catskill-white p-2 text-black text-xs rounded': !_vm.isSwappable,
    },"popover-arrow-class":"hidden","trigger":"hover"}},[_c('button',{staticClass:"btn tokens-swap-button__connect-button",class:{
        'btn--contrast': _vm.isSwappable,
        'btn--flat': !_vm.isSwappable,
      },attrs:{"disabled":!_vm.isSwappable,"type":"button"},on:{"click":_vm.connect}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('components.tokensSwapButton.buttonText'))+" ")]),_c('em',{staticClass:"text-2xl fas fa-angle-right",class:{
          'text-aquamarine': _vm.isSwappable,
          'text-regent-gray': !_vm.isSwappable,
        }})]),_c('template',{slot:"popover"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('components.tokensSwapButton.tooltipText'))+" ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }