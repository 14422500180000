









































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {TokensTableItem} from '@/model/resource/TokensTableItem'

@Component
export default class TokensSwapButton extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, required: true}) isSwappable!: boolean
  @Prop({type: TokensTableItem, required: true}) token!: TokensTableItem

  async connect() {
    await this.$walletAdapter.executeWithConnectedWallet(() => {
      this.$modal.open('swapModal', this.token)
    })
  }
}
